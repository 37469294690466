.dialog {
    position: relative;
    z-index: 1;

    &.dialog-confirm {}

    .dialog-backdrop {
        backdrop-filter: blur(1px);
        background-color: var(--primary-500-a30);

        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }

    .dialog-content {
        padding: 30px;
        border-radius: $borderradius;
        box-shadow: $boxshadow;
        border: $boxborder;
        background-color: $neutral-050;

        position: fixed;
        width: 30vw;
        height: auto;

        top: 50%;
        left: 50%;

        transform: translateX(-50%) translateY(-50%);
        h3, p {
            display: block;
        }
        h3 {
            margin-bottom: 20px;
        }
        .dialog-actions {
            display: flex;
            justify-content: space-between;
            padding-top: 60px;
        }
    }
}