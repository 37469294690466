.document-avatar {
    display: grid;
    width: 100%;
    gap: 2px 10px;
    grid-template-columns: 100px auto;
    grid-template-rows: 20px 20px 35px;

    padding: 20px;
    border-radius: $borderradius;
    border:$boxborder;
    box-shadow: $boxshadow;

    .label {
        font-size: 11px;
        font-weight: 400;
        @include maxlines(1);
    }
    .value {
        font-size: 14px;
        font-weight: 600;
        @include maxlines(1);
    }
    .action {
        padding-top: 15px;
    }
}