.vehicle-installed-termometer {
    display: grid;
    grid-template-columns: auto 100px;
    grid-template-rows: 35px 35px;
    gap:15px 10px;
    
    .trace {
        grid-column: 1;
        grid-row: 1;
    }

    .contract {
        grid-column: 1;
        grid-row: 2;
    }

    .analysis {
        grid-column: 2;
        grid-row: 1 / 3;
        line-height: 83px;
        position: relative;
        .upIndicator,
        .downIndicator {
            z-index: 0;
            position: absolute;
            left: -13px;
            &.danger {
                color:$danger-200;
            }
            &.success {
                color:$success-300;
            }
        }
        .tag {
            display: inline-block;
            position: relative;
            z-index: 2;
            border-radius: 0 7px 7px 0;
        }
        .upIndicator {
            top:23px
        }
        .downIndicator {
            bottom:23px
        }
    }

    &.layout-aside {
        grid-template-columns: calc(50% - 50px) 80px calc(50% - 50px);
        grid-template-rows: 35px;
        .contract {
            grid-column: 3;
            grid-row: 1;
        }
        .analysis {
            grid-column: 2;
            grid-row: 1;
            text-align: center;
            line-height: 58px;
            .tag {
                border-radius: 7px;
            }
            .upIndicator {
                left:0px;
            }
            .downIndicator {
                transform: rotate(180deg);
                bottom: -4px;
                left:63px;
            }
        }
    }
}