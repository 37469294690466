@import '../variables';
@import '../animations';
@import '../functions';
@import '../typography';

* {
    box-sizing: border-box;
}

body {
    font-family: $font-primary;
    margin: 0;
    padding: 0;

    .app {
        display: grid;
        grid-template-columns: repeat(24, 1fr);
        grid-template-rows: $app_top_border_height $header_height $module_menu_height $body_height $footer_height;
        background-color: var(--app-menu-background);

        .superheader {
            grid-row: 1 / 1;
            grid-column: 1 / 25;
        }

        header {
            grid-row: 2 / 2;
            grid-column: 5 / 25;
        }

        nav {
            grid-row: 3 / 3;
            grid-column: 5 / 25;
            border-left: 1px solid var(--app-maincanvas-border-color);
        }

        aside {
            grid-row: 2 / 5;
            grid-column: 1 / 5;
        }

        main {
            grid-row: 4 / 4;
            grid-column: 5 / 25;
        }

        footer {
            grid-row: 5 / 5;
            grid-column: 1 / 25;
        }

        &.closed {
            aside {
                grid-column: 1 / 1;
            }

            header {
                grid-column: 2 / 25;
            }

            nav {
                grid-column: 2 / 25;
            }

            main {
                grid-column: 2 / 25;
            }
        }

        &.driver {
            >nav {
                display: none
            }

            main {
                grid-row: 3 / 5;
            }

        }



        @import './Layouts/superheader';
        @import './Layouts/header';
        @import './Layouts/aside';
        @import './Layouts/main';
        @import './Layouts/footer';
    }

    .app-login {
        height: 100vh;
        background: linear-gradient(-45deg, var(--app-main), var(--app-main-dimmed));
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;

        main {
            width: 40vw;
            height: auto;
            background-color: $white;
            border-radius: 50px 8px 50px 8px;
            border: 0.5px solid $primary-100;
            border-top: 20px solid $primary-100;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            padding: 30px 30px 60px 30px;

            .app-login-panel {
                text-align: center;

                h4 {
                    margin-top: 45px;
                    text-align: center;
                    color: var(--app-main-dimmed);
                    display: block;
                }

                h1 {
                    text-align: center;
                    color: var(--app-main);
                    display: block;
                }

                p {
                    margin-top: 15px;
                }

                button {
                    margin-top: 30px;
                    padding: 0 15px;
                    line-height: 50px;
                    background-color: var(--app-main);

                    &:hover {
                        background-color: var(--app-main-dimmed);
                    }
                }

                .error {
                    display: block;
                    background: $danger-500;
                    color:$white;
                    text-align: center;
                    border-radius: 10px;
                    padding: 15px;
                    margin: 10px 0;
                }

                .logo {
                    svg {
                        width: var(--app-login-logo-width);
                    }
                }
            }

        }
    }
}

@media screen and (max-width: 600px) {
    body {
        font-family: $font-primary;
        margin: 0;
        padding: 0;

        .app {
            display: grid;
            grid-template-columns: repeat(24, 1fr);
            grid-template-rows: $header_height 1fr 50px 60px;
            background-color: $white;
            height: 100vh;
            height: 100dvh;
            overflow: hidden;

            &.open,
            &.closed {
                .superheader {
                    display: none;
                }

                header {
                    grid-row: 1 / 1;
                    grid-column: 1 / 25;
                    background-color: var(--app-menu-background);
                    color: $white;
                    border-radius: 0;
                    padding: 5px calc(100% / 24);
                    height: 70px; // così mi fa da sfondo allo stondo di main
                    border: none;
                }

                main {
                    grid-row: 2 / 2;
                    grid-column: 1 / 25;
                    padding: 40px calc(100% / 24);
                    //border-radius: 10px 10px 0 0; // per ora commento, mi da fastidio allo sfondo di _dashboard.dashboard-driver
                    border-radius: 0;
                    z-index: 1;
                    border: none;
                }

                nav {
                    grid-row: 3 / 3;
                    grid-column: 1 / 25;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    border-top: 1px solid var(--app-maincanvas-border-color);
                    border-radius: 10px 10px 0 0;
                    box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1);
                }

                aside {
                    grid-row: 4 / 4;
                    grid-column: 1 / 25;
                    background-color: var(--app-menu-background);
                    border-radius: 10px 10px 0 0;
                    position: relative;
                    z-index: 2000;
                }

                footer {
                    display: none;
                }


            }

            &.driver {
                grid-template-rows: $header_height 1fr 0px 60px;

                >nav {
                    display: none
                }
            }
        }

        .app-login {
            main {
                width: 90vw;
            }
        }
    }
}