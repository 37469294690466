aside {
    background-color: var(--app-menu-background);
    position: relative;
    transition-duration: 2s;

    .aside-minimizer {
        position: absolute;
        width: 30px;
        height: 30px;
        right: -15px;
        top: 50%;
        transform: translateY(-50%);

        button {
            font-size: 10px;
            width: 30px;
            height: 30px;
            min-width: unset;
            min-height: unset;
            border-radius: 50%;
            background: var(--app-menu-background);
            border: 1px solid var(--app-maincanvas-border-color);
            color: var(--app-menu-background-flat-text);

            &:hover {
                background-color: var(--app-menu-background-item-highlight);
            }
        }

        &.hidden {
            display: none;
        }
    }

    &.closed {
        .appname {
            .name {
                display: none;
            }
        }

        .legalentity-menu {
            display: block;
            position: relative;
            height: 42px;
            padding: 6px;

            .legalentity-icon {
                height: 32px;

                .company-avatar-squared {
                    width: 85%;
                    height: 20px;
                    line-height: 18px;
                    top: 2px;
                    left: 2px;
                }
            }

            .legalentity-name {
                display: none;
            }

            .legalentity-list {
                position: absolute;
                top: 0;
                left: 0;
                width: 46px;

                .selector-icon {
                    display: none;
                }
            }
        }

        

        .app-menu {
            .app-menu-portion {

                >li {
                    padding: 3px;

                    .MuiLink-startDecorator {
                        width: 100%;
                    }

                    .name {
                        display: none;
                    }
                }
            }
        }

        .user-menu {
            grid-template-columns: 32px;
            height: 32px;

            .user-icon {
                line-height: 32px;
                font-size: 12px;
                width: 32px;
                height: 32px;
                background-color: var(--app-menu-background-flat-text);
                color: var(--app-menu-background);
                border: 0;
            }

            .user-name {
                display: none;
            }

            .user-email {
                display: none;
            }

            .user-logout {
                display: none;
            }

            .languageSwitcher {
                bottom: -13px;
            }
        }

        .debugger {
            display: none;
        }
    }

    &.closed,
    &.open {
        .dialog {
            .legalentity-menu {
                position:relative;
                display: grid;
                grid-template-columns: 60px calc(100% - 66px) 26px;
                margin: 0;
                height: 60px;
                padding: 6px;

                .legalentity-icon {
                    height: 48px;

                    .company-avatar-squared {
                        width: 50px;
                        height: 36px;
                        line-height: 34px;
                    }
                }

                .legalentity-name {
                    display: inline;
                }

                .legalentity-list {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 60px;

                    .form-element-field-select {
                        height: 60px;
                    }

                    .selector-icon {
                        display: block;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 600px) {
    aside {

        &.closed,
        &.open {

            .appname,
            .aside-minimizer,
            .legalentity-menu {
                display: none;
            }
        }
    }
}