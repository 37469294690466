.list-system {
    .list-wrapper {
        border-radius: 12px;
        background-color: $white;
        border: 0.5px solid $neutral-300;
        box-shadow: $boxshadow;

        .list-toolbar {
            border-bottom: 0.5px solid $neutral-300;
            min-height: 44px;
            padding: 20px 24px;
            justify-content: space-between;

            .list-toolbar-secondary {
                .MuiFormControl-root {
                    padding: 0 10px;
                    border: 1px solid $neutral-300;
                    border-radius: 8px;

                    .MuiInputBase-root {
                        &::before {
                            border: unset;
                        }

                        input {
                            height: 44px;
                            padding: 0;
                        }
                    }
                }
            }

            .list-toolbar-primary {
                display: flex;

                button {
                    margin-left: 5px;
                }
            }
        }

        .MuiDataGrid-main {
            border-radius: 0 !important;
            cursor: pointer;

            .MuiDataGrid-virtualScroller {
                border-radius: 0 !important;

                .MuiDataGrid-columnHeaders {
                    border-radius: 0 !important;
                    background: $neutral-100;
                    --DataGrid-headerHeight: 44px;

                    *,
                    >div {
                        background-color: transparent;
                    }

                    .MuiDataGrid-columnHeader {
                        height: 44px !important;

                        &:focus,
                        &:focus-within {
                            outline: unset;
                        }

                        .MuiDataGrid-columnHeaderTitleContainer {
                            .MuiDataGrid-columnHeaderTitle {
                                color: $neutral-600;
                                font-size: 12px;
                                font-weight: 400;
                                text-transform: capitalize;
                            }
                        }

                        &.MuiDataGrid-columnHeader--sorted {
                            .MuiDataGrid-iconButtonContainer {
                                .MuiSvgIcon-root {
                                    fill: $secondary-500;
                                }
                            }
                        }
                    }



                    .MuiDataGrid-headerFilterRow {

                        .MuiDataGrid-columnHeader {
                            .MuiFormControl-root {
                                font-size: 10px;
                                height: 35px;

                                label {
                                    font-size: 10px;
                                    top: -18px;
                                    position: absolute;

                                    &[data-shrink="true"] {
                                        color: $neutral-600;
                                        top: -9px;
                                        background: $neutral-100;
                                        padding: 0 5px;
                                        border: 1px solid $neutral-300;
                                        border-radius: 8px;
                                    }
                                }

                                .MuiInputBase-root {
                                    margin: 4px 0;

                                    input {
                                        font-size: 12px;
                                        height: 23px;
                                    }

                                    .MuiSelect-select {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }

                        .MuiButtonBase-root {
                            padding: 0;
                            width: 15px;
                            width: 15px;
                            margin-left: 0;

                            svg {
                                font-size: 10px;
                            }
                        }

                        [title="Operator"] {
                            //display: none;
                        }
                    }
                }

                .MuiDataGrid-row {
                    .MuiDataGrid-cell {
                        font-family: 'Inter';

                        &:focus,
                        &:focus-within {
                            outline: 0.5px solid $secondary-500;
                        }
                    }
                }
                .MuiDataGrid-overlayWrapper {
                    top:0;
                    color: $neutral-300;
                }
            }
        }

        .MuiSvgIcon-root {
            font-size: 1.2rem;
            fill: $neutral-300;
        }

        .MuiCheckbox-indeterminate,
        .Mui-checked {
            .MuiSvgIcon-root {
                fill: $secondary-500;
            }
        }

        .list-footer {
            border-top: 0.5px solid $neutral-300;

            label {
                font-size: 14px;
            }

            .list-footer-select {
                height: 30px;
                font-size: 0.8rem;
            }
        }
    }
}

.MuiDataGrid-menu {
    .MuiListItemIcon-root {
        min-width: 30px;
        color: $neutral-300;
    }

    .MuiTypography-root {
        font-family: 'Inter';
        font-size: 12px;
        color: $neutral-600;
    }
}

.MuiDataGrid-panel {
    inset: 20px 370px auto auto !important;

    .MuiPaper-elevation {
        box-shadow: $boxshadowstrong;

        .MuiDataGrid-columnsManagement {
            .MuiDataGrid-columnsManagementRow {
                margin-bottom: 5px;
                margin-left: 0px;

                .MuiCheckbox-sizeMedium {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                    margin-left: 5px;
                }

                .MuiFormControlLabel-label {
                    font-size: 12px;
                }
            }
        }
    }

}


@media screen and (max-width: 600px) {
    .list-system {
        .list-wrapper {
            .list-toolbar {
                display: grid;
                padding: 10px;
                grid-template-columns: auto auto;

                .list-toolbar-primary {
                    grid-column: 2;

                    .button-import,
                    .button-export,
                    .list-toolbar-activator {
                        display: none;
                    }

                    .button-delete {
                        span {
                            font-size: 14px;
                            margin: 0;
                        }

                        font-size: 0;
                    }
                }

                .list-toolbar-secondary {
                    grid-column: 1;

                    .MuiFormControl-root {
                        width: 100%;
                    }
                }
            }

            .list-footer {
                grid-template-columns: 40% 40% 10%;
                gap: 0 5%;
            }
        }
    }
}