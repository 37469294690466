.vehicle-card-wrapper {
    padding: 30px;
    border: $boxborderstrong;
    box-shadow: $boxshadowstrong;
    border-radius: $borderradius;
    padding-bottom: 140px;

    color: $neutral-800;
    position: relative;
    box-sizing: border-box;

    .vehicle-card {
        .vehicle-card-clickable {
            cursor: pointer;

        }

        .vehicle-avatar-wrapper {
            margin-bottom: 20px;
            .vehicle-avatar {
                margin-left: -11px;
                width: 50px;
                height: 50px;
            }
        }

        .clickableIcon {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 10px;
            color: $primary-300;
        }

        .info {
            margin-left: 60px;
            margin-bottom: 10px;

            .label {
                margin: 0;
                color: $neutral-300;
                font-size: 14px;
            }

            .value {
                margin: 0;
            }

            &.highlight {
                background-color: $neutral-100;
                border-radius: 10px;
                padding: 20px;
                margin-top: 20px;

                >* {
                    font-size: 12px;
                }

                &:empty {
                    display: none;
                }
            }

            .info-contract {
                span.MuiTypography-startDecorator {
                    width: 30px;
                }
            }
        }

        .info-tracekm {
            background-color: $neutral-200;
            position: absolute;
            left: 0;
            bottom: 0;
            margin: 0;
            width: 100%;
            padding: 10px 20px 20px 90px;
            border-radius: 0 0 5px 5px;

            .label {
                color: $neutral-500;
                font-size: 12px;
            }

            .value {
                color: $neutral-900;

                &.primary {
                    font-weight: 700;
                    font-size: 20px;
                    margin-bottom: 5px;
                }
            }

            .addTrack {
                background-color: $neutral-600;
                position: absolute;
                left: 20px;
                top: 20px
            }

            &.info-tracekm-success {
                background-color: $success-300;

                .label {
                    color: $success-500;
                }

                .value {
                    color: $neutral-900;
                }

                .addTrack {
                    background-color: $success-600;
                }
            }

            &.info-tracekm-warning {
                background-color: $warning-300;

                .label {
                    color: $warning-500;
                }

                .value {
                    color: $neutral-900;
                }

                .addTrack {
                    background-color: $warning-600;
                }
            }

            &.info-tracekm-danger {
                background-color: $danger-300;

                .label {
                    color: $danger-500;
                }

                .value {
                    color: $neutral-900;
                }

                .addTrack {
                    background-color: $danger-600;
                }
            }
        }
    }
}