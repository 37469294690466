.mtska-tabs {
    font-family: $font-primary !important;
    background-color: transparent !important;

    .mtska-tab-list-scroller {
        .mtska-tab-list {
            justify-content: left;
            gap: 25px;
            margin-bottom: 20px;
            z-index: 0;

            .mtska-tab {
                line-height: 46px;
                font-size: 14px;
                background-color: transparent;
                padding: 0 10px;
                border: none;
                //@include maxlines(1);
                white-space: nowrap;

                &::after {
                    //background-color: $primary-100;
                }

                &.Mui-selected {
                    background-color: $primary-050;
                    color: $primary-400;

                    &::after {
                        background-color: $primary-400;
                    }
                }

                &.mtska-tab-hidden {
                    display: none;
                }
            }
        }
    }

    .mtska-tab-panel {
        padding: 10px 0;
        background-color: transparent;

        .mtska-fieldset {
            padding: 13px 10px;
            background-color: $white;
            border-radius: 10px;
            margin-bottom: 20px;
            box-shadow: $boxshadow;
            border: $boxborder;
            margin: 0 0 20px;

            .mtska-fieldset-title {
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .mtska-tabs {
        .mtska-tab-list-scroller {
            overflow-x: auto;

            .mtska-tab-list {
                display: inline-flex;
                min-width: 100%;
                .mtska-tab {
                    display: inline-block;
                    font-size: 12px;
                }
            }
        }
    }
}