.user-menu {
    display: grid;
    grid-template-columns: 40px calc(100% - 80px) 20px;
    grid-template-rows: 25px 25px;
    gap: 0 10px;
    padding: 0 20px;
    width: 100%;

    .user-icon {
        grid-column: 1 / 1;
        grid-row: 1 / span 2;
        border-radius: 25px;
        background-color: var(--app-menu-background-flat-text);
        color: var(--app-menu-background);
        font-size: 14px;
        line-height: 40px;
        height: 40px;
        margin-top: 5px;
        letter-spacing: -1px;
        text-align: center;

        &.MuiButton-variantSolid {
            background-color: var(--app-menu-background-flat-text);
        }
    }

    .user-name {
        grid-column: 2 / 2;
        grid-row: 1 / 1;
        font-size: 14px;
        line-height: 28px;
        color: var(--app-menu-background-flat-text);
        @include maxlines(1);
        font-weight: 700;
    }

    .user-email {
        grid-column: 2 / 2;
        grid-row: 2 / 2;
        font-size: 14px;
        line-height: 22px;
        color: var(--app-menu-background-flat-text);
        @include maxlines(1);
        font-weight: 300;
    }

    .user-logout {
        grid-column: 3 / 3;
        grid-row: 1 / span 2;
        font-size: 0;

        // position: absolute;
        // top:20px;
        // right: 30px;
        svg {
            path {
                font-size: 0px;
                fill: var(--app-menu-background-flat-text);
            }
        }

        &:hover {
            background-color: transparent !important;

            svg {
                path {
                    fill: var(--app-menu-background-flat-text-hover);
                }
            }
        }
    }

    .languageSwitcher {
        text-align: center;
        position: absolute;
        left: 15px;
        bottom: 0;

        .flag {
            width: 16px;
            height: 16px;
            border-radius: 8px;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            border: 0.5px solid $white;
            svg {
                position: relative;
                top:50%;
                left:50%;
                transform: translateX(-50%) translateY(-50%);
                
                height: 16px;
                width: auto;
            }

            &:hover {
                opacity: 0.5;
            }
        }
    }
}