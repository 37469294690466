.tag {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    border-radius: 16px;
    padding: 2px 8px;
    border:0.5px solid $neutral-100;
    background-color: $neutral-100;
    color:$neutral-600;
    white-space: nowrap;

    svg {
        margin-right: 5px;
    }
    
    &.tag-success {
        background-color: $success-100;
        border-color: $success-100;
        color:$success-600;
    }

    &.tag-danger {
        background-color: $danger-100;
        border-color: $danger-100;
        color:$danger-600;
    }

    &.tag-warning {
        background-color: $warning-100;
        border-color: $warning-100;
        color:$warning-600;
    }

    &.tag-primary {
        background-color: $primary-100;
        border-color: $primary-100;
        color:$primary-600;
    }

    &.tag-secondary {
        background-color: $secondary-100;
        border-color: $secondary-100;
        color:$secondary-600;
    }

    &.tag-medium {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    &.tag-active {
        cursor: pointer;
        &:hover {
            &.tag-success {
                border-color:$success-600;
            }
        
            &.tag-danger {
                border-color:$danger-600;
            }
        
            &.tag-warning {
                border-color:$warning-600;
            }
        
            &.tag-primary {
                border-color:$primary-600;
            }
        
            &.tag-secondary {
                border-color:$secondary-600;
            }
        }
    }
}