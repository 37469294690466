.driving-licence-avatar {
    display: grid;
    gap: 2px 10px;
    grid-template-columns: repeat(3, 3fr);
    grid-template-rows: 20px auto;

    .label {
        font-size: 11px;
        font-weight: 400;
        @include maxlines(1);

        &.id {
            grid-column: 1;
            grid-row: 1;
        }
        &.expiration {
            grid-column: 2;
            grid-row: 1;
        }
        &.status {
            grid-column: 3;
            grid-row: 1;
        }
    }
    .driving-licence-id {
        font-size: 14px;
        font-weight: 400;
        grid-column: 1;
        grid-row: 2;
    }
    .driving-licence-expiration {
        font-size: 12px;
        font-weight: 200;
        grid-column: 2;
        grid-row: 2;
    }
    .driving-licence-status {
        grid-column: 3;
        grid-row: 2;
    }
}