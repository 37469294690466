button,
a.buttonized {

    &.MuiButton-colorPrimary {
        color: var(--app-main);
    }

    &.input-button {
        font-weight: 400;

        &.MuiButton-variantOutlined {
            color:var(--app-main);
            border-color: var(--app-main);
            &:hover {
                color:var(--app-main-shadowed);
                border-color: var(--app-main-shadowed);
                background-color:var(--white);
            }
        }

        &.MuiButton-variantSoft {
            color:var(--app-main);
            background-color: var(--app-main-light);
            &:hover {
                color:var(--white);
                background-color: var(--app-main-dimmed);
            }
        }

        &.MuiButton-variantSolid {
            color:var(--white);
            background-color: var(--app-main);
            &:hover {
                background-color: var(--app-main-shadowed);
            }
        }
        

        &.tiny {
            font-size: 10px;
            line-height: 16px;
            font-weight: 400;
        }

        &.small {
            font-size: 11px;
            line-height: 16px;
            font-weight: 400;
        }

        &.medium {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
        }

        &.regular {
            font-size: 14px;
            line-height: 24px;
        }

        &.big {
            font-size: 22px;
            line-height: 34px;
        }

        .MuiButton-startDecorator,
        .MuiButton-endDecorator {
            &:empty {
                display: none;
            }
        }

        &.iconic {
            padding: 5px;
            border-radius: 18px;
            width: 36px;
            height: 36px;

            .MuiButton-startDecorator {
                margin: 0;
            }
        }

        &:disabled {
            opacity: 0.2;
            filter: grayscale(0.5);
        }
    }
}
a.buttonized {
    padding: 5px 10px;
    border-radius: var(--Button-radius, var(--joy-radius-sm, 6px));
    display: inline;
    text-decoration: none;
    text-align: center;
}

@media screen and (max-width: 600px) {
    button {
        &.input-button {
            letter-spacing: normal;
            &.tiny {
                font-size: 9px;
                line-height: 14px;
                font-weight: 400;
            }
    
            &.small {
                font-size: 10px;
                line-height: 16px;
                font-weight: 400;
            }
    
            &.medium {
                font-size: 11px;
                line-height: 18px;
                font-weight: 400;
            }
    
            &.regular {
                font-size: 12px;
                line-height: 24px;
                font-weight: 400;
            }
    
            &.big {
                font-size: 16px;
                line-height: 34px;
            }
        }
    }
}