.dashboard-menu {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    list-style-type: none;
    padding: 0;
    margin: 50px 0 0;

    &.dashboard-menu-1col {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }

    &.dashboard-menu-5col {
        grid-template-columns: repeat(5, 1fr);
    }

    .dashboard-section-title {
        grid-column: 1 / -1;
        margin-top: 40px;
        position: relative;

        &:first-child {
            margin-top: 0;
        }

        h2 {
            margin: 0;
            color: $primary-600;
        }

        .buttonized {
            position: absolute;
            top: 5px;
            right: 0;
        }
    }

    .dashboard-menu-itemgroup {
        display: flex;
        gap: 20px;

        .dashboard-menu-item {
            flex: 1;
            max-height: 280px;

            &.autoheight {
                max-height: unset;
                height: auto;
                padding-bottom: 10px;
            }

            .mtska-chart {
                p {
                    color: $primary-500;
                    margin-bottom: 10px;
                }

                .chartwrapper {
                    max-height: 200px;
                    height: 100%;
                    position: relative;
                    // > div[type="donut"] {
                    //     .apexcharts-canvas {
                    //         width: auto !important;
                    //         height: 100% !important;
                    //         min-height: unset !important;
                    //     }
                    // }
                }
            }

            &.mtska-chart-kpi {
                max-height: 140px;

                .iconwrapper {
                    border-color: $primary-500;
                    background-color: $primary-500;

                    svg {
                        color: $white;
                    }
                }

                .title {
                    color: $primary-500;
                }

                .score {
                    color: $primary-600;
                    font-size: 30px;
                    line-height: 50px;
                }
            }
        }
    }

    .dashboard-menu-item {
        padding: 30px;
        border: $boxborder;
        box-shadow: $boxshadow;
        border-radius: $borderradius;

        color: $neutral-800;


        >svg {
            font-size: 30px;
        }

        h2 {
            color: $danger-500;
        }

        .dashboard-submenu {
            list-style-type: none;
            margin: 0;
            padding: 0;

            .dashboard-submenu-item {
                font-size: 12px;
                line-height: 20px;
                color: $neutral-300;

                &.hidden {
                    display: none;
                }

                a {
                    color: $neutral-800;
                    text-decoration: none;
                    font-size: 12px;

                    &:visited {
                        color: $neutral-800;
                    }

                    &:hover {
                        color: $danger-500;
                    }
                }
            }
        }


        &.dimmed {
            svg {
                color: $neutral-300;
            }

            h2 {
                color: $neutral-400;
            }
        }
    }
}

.dashboard {
    ul.dashboard-portion {
        display: grid;
        gap: 30px;
        padding: 0;
        margin: 0;
        margin-top: 30px;
    }

    .dashboard-portion-title {
        font-weight: 700;
        color: $primary-600;
        font-size: 18px;
        margin: 50px 0 10px;

        &:first-child {
            margin-top: 0;
            mix-blend-mode: multiply;
        }
    }

    .dashboard-portion-subtitle {
        color: $neutral-400;
        font-size: 14px;
    }

    .dashboard-portion-driver-info {
        background-color: $neutral-100;
        border-radius: $borderradius;
        box-shadow: $boxshadow;
        border: $boxborder;
        padding: 30px;
    }

    &.dashboard-driver {
        position: relative;
        margin-top: 40px;

        &:before {
            content: "";
            background-color: $white;
            width: calc(100% + 34px);
            height: 140px;
            position: absolute;
            top: -40px;
            left: -17px;
            z-index: -1;
            border-radius: 0px 0px 20px 40px;
        }

        .vehicle-avatar-wrapper,
        .person-avatar-wrapper {

            .vehicle-avatar,
            .person-avatar {
                width: 50px;
                height: 50px;
                min-width: 50px;
                line-height: 50px;
                border-radius: 50%;
                font-size: 18px;
                margin-left: -10px;
                margin-right: 10px;
            }

            .vehicle-avatar {
                margin-left: -15px;
                margin-right: 15px;

                img.default {
                    opacity: 0.9;
                }
            }

            .vehicle-name,
            .person-name {
                line-height: 18px !important;
                
                .fullname {
                    font-size: 18px;
                }

                .detail {
                    font-size: 14px;
                }
            }

            .person-name {
                max-width: calc(100% - 50px);
                .fullname {
                    color: $primary-900;
                    font-weight: 700;
                    @include maxlines(1)
                }

                .detail {
                    color: $primary-200;
                    @include maxlines(1)
                }
            }
        }

        .vehicle-avatar-wrapper {
            margin-bottom: 20px;

            .vehicle-name {
                .fullname {
                    font-weight: 700;
                }
            }
        }

        .company-avatar-wrapper {
            margin-bottom: 20px;
        }

        .driving-licence-avatar {
            margin-left: 60px;
            margin-top: 30px;
            max-width: 500px;

            &:before {
                content: "";
                background-color: $primary-300;
                width: calc(100% + 34px);
                height: 210px;
                position: absolute;
                top: -40px;
                left: -17px;
                z-index: -2;
                border-radius: 0px 0px 20px 40px;
                background: $primary-300;
                background-size: 400% 400%;
                animation: gradient 15s ease infinite;
            }
        }
    }


}


@media screen and (max-width: 600px) {
    .dashboard {
        ul.dashboard-portion {
            display: flex;
        }
    }
}