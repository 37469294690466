.relationship-elements {
    .relationship-element {
        height: $fieldheight;

        .relationship-element-content {
            >div {
                font-size: 14px;
                border-right: 0.5px solid $neutral-300;

                &.coupled {
                    padding: 0px 10px;
                    line-height: unset;

                    .label {
                        display: block;
                        font-size: 14px;
                        font-weight: 500;
                        color: $neutral-900;
                        line-height: 25px;
                        @include maxlines(1);

                        svg {
                            margin-right: 8px;
                            color: $neutral-500;
                            font-size: 0.8em;
                        }
                    }

                    .value {
                        display: block;
                        font-size: 13px;
                        font-weight: 300;
                        color: $neutral-600;
                        line-height: 25px;
                        @include maxlines(1);

                        svg {
                            margin-right: 8px;
                            color: $neutral-500;
                            font-size: 0.8em;
                        }
                    }

                    &.highlight {
                        background-color: $danger-100;
                        border-radius: 3px;
                        margin: -2px;
                        padding-top: 2px;

                        .label,
                        .value {
                            color: $danger-400;
                            font-size: 0.9em;
                        }

                        &[data-isfuture="true"] {
                            background-color: $success-200;

                            .label,
                            .value {
                                color: $success-400;
                            }
                        }
                    }
                }

                &.icon-file {
                    border-right: 0;
                    position: relative;
                    text-align: center;

                    padding-top: 5px;
                    height: 47px;

                    .filepreview {
                        width: 42px;
                        aspect-ratio: 1 / 1;
                        object-fit: cover;
                        border-radius: 21px;
                    }

                    .fileicon {
                        font-size: 42px;
                        color: $neutral-300;
                    }

                    .extension {
                        position: absolute;
                        display: inline-block;
                        background-color: $danger-500;
                        border-radius: 2px;
                        color: $white;
                        line-height: 14px;
                        font-size: 9px;
                        font-weight: 500;
                        padding: 0 3px;
                        top: 25px;
                        left: 0;
                    }
                }

                &.norightborder,
                &.company-avatar-withname-false {
                    border-right: 0px;
                }

                &.status {
                    text-align: center;
                }

                &.analysis {
                    padding: 5px 10px;
                }
            }
        }
    }

    &.relationship-elements-car {
        .relationship-element {
            height: 100px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 240px 150px 240px 150px auto;
                height: 80px;
                margin: 10px 0;
                font-size: 14px;

                >div {
                    padding: 0 10px;
                    line-height: 80px;
                }

                .makeandorder {
                    padding: 20px 10px;
                    line-height: unset;

                    .make {
                        font-weight: 500;
                        color: $neutral-900;
                        @include maxlines(1);
                    }

                    .order {
                        color: $neutral-600;
                        font-weight: 300;
                        font-size: 13px;
                    }
                }

                .plate {
                    text-align: center;
                }

                .ownership {
                    padding: 20px 10px;
                    line-height: 40px;

                    .owned {
                        font-weight: 500;
                    }
                }

                .analysis {
                    border-right: none;
                    position: relative;

                    &.analysis-installed {
                        padding-top: 0px;
                    }

                    &.analysis-ordered {
                        padding-top: 20px;
                    }
                }

                &.vehicle-type-owned {
                    .ownership {
                        border-right: none;
                    }

                    .status,
                    .analysis {
                        display: none;
                    }
                }
            }
        }
    }


    &.relationship-elements-contract {
        .relationship-element {
            height: 100px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 200px 120px 90px 110px 110px 110px auto;

                .company-avatar-wrapper {
                    margin-top: 5px;
                }
            }
        }
    }


    &.relationship-elements-fileGeneric,
    &.relationship-elements-document,
    &.relationship-elements-documents,
    &.relationship-elements-licence,
    &.relationship-elements-picture {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 52px 200px 100px 200px 200px auto;
                height: 52px;
                margin: 10px 0;
                font-size: 14px;
                line-height: 52px;

                .type {
                    padding: 0 5px;
                    line-height: 12px;
                    font-size: 11px;
                    display: flex;
                    justify-content: left;
                    align-items: center;
                }
            }
        }
    }

    &.relationship-elements-tyreSet {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 50px 180px 120px 180px auto;
                height: 52px;
                margin: 10px 0;
                font-size: 14px;
                line-height: 52px;

                .quantity {
                    font-size: 36px;
                    font-weight: 700;
                    letter-spacing: -2px;
                    padding-right: 10px;
                    text-align: center;
                }

                .status {
                    text-align: center;
                }
            }
        }
    }

    &.relationship-elements-fileDriverLicence {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 52px 150px 180px 180px auto;
                height: 52px;
                margin: 10px 0;
                font-size: 14px;
                line-height: 52px;


                .licenceid {
                    text-align: center;
                }
            }
        }
    }

    &.relationship-elements-driverdelegate {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 52px 200px 200px 200px 60px 200px auto;
                height: 52px;
                margin: 10px 0;
                font-size: 14px;
                line-height: 52px;

                .person-avatar {
                    width: 40px;
                    height: 40px;
                    margin-top: 5px;
                    line-height: 40px;
                }

                .name {
                    font-weight: 500;
                    color: $neutral-900;
                    @include maxlines(1);
                }

                .relativitydegree,
                .licence {
                    padding: 0 30px;
                }

                .icon-file {
                    margin-left: 10px;
                }
            }
        }
    }

    &.relationship-elements-service,
    &.relationship-elements-saltedgeconnection,
    &.relationship-elements-saltedgeaccount {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 240px 240px 80px auto;
                align-items: center;

                &.fuelcard {
                    grid-template-columns: 240px 240px 80px 120px auto;
                }

                &.tollsystem {
                    grid-template-columns: 240px 240px 80px 120px auto;
                }

                >div {
                    line-height: 50px;
                }

                .termometer {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                .company-avatar {
                    margin: 5px 10px;
                    line-height: 40px;
                }
            }
        }
    }


    &.relationship-elements-traceKm,
    &.relationship-elements-traceKms,
    &.relationship-elements-attributionrule {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 240px 240px auto;
                align-items: center;

                >div {
                    line-height: 50px;
                }
            }
        }
    }

    &.relationship-elements-cashflowrecordgroup {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 300px 170px 170px auto;
                align-items: center;

                >div {
                    line-height: 50px;
                }
            }
        }
    }

    &.relationship-elements-contoeconomicorecordgroup {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 300px 170px 170px auto;
                align-items: center;

                >div {
                    line-height: 50px;
                }
            }
        }
    }

    &.relationship-elements-contoeconomicorecord {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 300px 170px auto;
                align-items: center;

                >div {
                    line-height: 50px;
                }
            }
        }
    }

    &.relationship-elements-setup {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 180px 180px 260px 100px;
                align-items: center;

                >div {
                    line-height: 50px;
                }
            }
        }
    }

    &.relationship-elements-fringebenefitcar {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 320px 60px 90px 90px 90px 90px 90px 90px auto;
                align-items: center;

                >div {
                    line-height: 50px;
                }
            }
        }
    }

    &.relationship-elements-fringebenefitvalue {

        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 60px 120px 90px 90px 90px 90px 90px 90px auto;
                align-items: center;

                >div {
                    line-height: 50px;
                }
            }
        }
    }

    &.relationship-elements-person {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 340px 240px auto;

                >div {
                    line-height: 50px;
                }

                .person-avatar {
                    margin: 5px 10px;
                    line-height: 40px;
                }

                .email {
                    margin: 0px 10px;
                    @include maxlines(1);
                }
            }
        }
    }

    &.relationship-elements-pool {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 240px 140px 120px 120px 120px auto;

                >div {
                    line-height: 50px;
                }

                .type {
                    text-align: center;
                }

                .person-avatar {
                    margin: 5px 10px;
                    line-height: 40px;
                }

                .plate {
                    margin-left: 4px;
                }
            }
        }
    }

    &.relationship-elements-employment,
    &.relationship-elements-employee {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 240px 180px 180px auto;

                >div {
                    line-height: 50px;
                }

                .person-avatar-wrapper {
                    .person-avatar {
                        margin: 5px 10px;
                        line-height: 40px;
                        min-width: 40px;
                    }

                    .fullname {
                        @include maxlines(1);
                    }
                }
            }
        }
    }

    &.relationship-elements-employments {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 180px 180px 180px 180px 120px auto;

                >div {
                    line-height: 50px;
                }

                .role {
                    font-weight: 600;
                    @include maxlines(1);
                }

                .person-avatar {
                    margin: 5px 10px;
                    line-height: 40px;
                }
            }
        }
    }

    &.relationship-elements-employmentsWithPerson {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 280px 180px 180px auto;

                >div {
                    line-height: 50px;
                }

                .person-avatar {
                    margin: 5px 10px;
                    line-height: 40px;
                }
            }
        }
    }

    &.relationship-elements-legalEntity {
        .relationship-element {
            height: 72px;

            .relationship-element-content {
                display: grid;
                grid-template-columns: 60px 250px 250px auto;

                >div {
                    line-height: 50px;
                }

                .name {
                    font-weight: 500;
                    letter-spacing: -0.5px;
                    @include maxlines(1);
                }

                .url {
                    padding: 0 10px;

                    .value {
                        a {
                            line-height: 20px;
                            font-size: 12px;
                            @include maxlines(1);
                        }
                    }
                }

                .company-avatar {
                    margin: 5px 10px;
                    line-height: 40px;
                }

                .tags {
                    padding: 0 10px;

                    .tag {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}