.termometer {
    $railheight: 10px;
    $labelheight: 25px;

    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-template-rows: $labelheight $railheight $labelheight;
    line-height: $labelheight;
    font-size: 11px;
    gap: 0px 5px;

    .startvalue {
        grid-column: 1;
        grid-row: 1;
        white-space: nowrap;
    }

    .endvalue {
        grid-column: 3;
        grid-row: 1;
        text-align: right;
        white-space: nowrap;
    }

    .rail {
        grid-column: 1 / 4;
        grid-row: 2;
        line-height: $railheight;
        background-color: $neutral-200;
        border-radius: 7px;
        position: relative;

        .runner {
            background-color: $neutral-600;
            border-radius: 7px;
            height: $railheight;
        }

        .percentage {
            position: absolute;
            line-height: $railheight;
            color:$neutral-050;
            font-size: 9px;
            top:-3px;
            line-height: $railheight + 6;
            transform: translateX(-50%);
            border-radius: 8px;
            padding: 0 6px;
        }

        &.rail-success {

            .runner,
            .percentage {
                background-color: $success-400;
            }
        }

        &.rail-danger {

            .runner,
            .percentage {
                background-color: $danger-500;
            }
        }

        &.rail-warning {

            .runner,
            .percentage {
                background-color: $warning-400;
            }
        }

        &.rail-primary {

            .runner,
            .percentage {
                background-color: $primary-600;
            }
        }

        &.rail-secondary {

            .runner,
            .percentage {
                background-color: $secondary-600;
            }
        }
    }


    .hint {
        grid-column: 2;
        grid-row: 1;
        text-align: center;
        white-space: nowrap;

        &.hint-success {
            color: $success-400;
        }

        &.hint-danger {
            color: $danger-500;
        }

        &.hint-warning {
            color: $warning-400;
        }

        &.hint-primary {
            color: $primary-600;
        }

        &.hint-secondary {
            color: $secondary-600;
        }
    }

    &.termometer-bottom {
        grid-template-rows: $railheight $labelheight;

        .rail {
            grid-row: 1;
        }

        .percentage {
            grid-row: 1;
        }

        .hint {
            grid-row: 2;
        }
    }
}



@media screen and (max-width: 600px) {
    .termometer {
        grid-template-columns: 30% 40% 30%;
        .hint {
            display: none;
        }
    }
}