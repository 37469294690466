header {
    position: relative;
    background-color: var(--app-maincanvas-background);
    display: flex;
    height: $header_height;
    padding:$header_padding-top 0 0 $body_padding_right;
    border-radius: 25px 0 0 0;
    border-top: 1px solid var(--app-maincanvas-border-color);
    border-left: 1px solid var(--app-maincanvas-border-color);
    .filler {
        flex-grow: 1;
    }

}