.appname {
    display: flex;
    flex-direction: row;

    .logo {
        //width: calc(var(--app-menu-logo-width) + 20px);
        width: 100%;
        height: $header_height;
        
        text-align: center;

        img,
        svg {
            width: var(--app-menu-logo-width);
            margin-left: 5px;
            height: calc($header_height - 30px);
            @include centerelement($header_height, calc($header_height - 30px - $header_padding-top));
        }

    }

    .name {
        height: $header_height;
        padding-top: $header_padding-top;
        @include maxlines(1);

        h1 {
            font-family: $font-primary;
            margin: 0;
            font-size: 24px;
            letter-spacing: 0px;
            font-weight: 700;
            line-height: $header_height - $header_padding-top;
            color: var(--app-menu-app-title);
            padding-right: 20px;
        }
    }
}