.vehicle-renter {
    display: grid;
    grid-template-columns: 40px auto;
    grid-template-rows: 20px 20px;
    gap:0px 10px;
    .logo {
        grid-column: 1;
        grid-row: 1 / 2;
        height: 40px;
        line-height: 40px;
        img {

            border-radius: 50%;
        }
    }
    .name {
        grid-column: 2;
        grid-row: 1 ;
        line-height: 20px;
        font-weight: 500;
        color:$neutral-900;
    }
    .fringe {
        grid-column: 2;
        grid-row: 2 ;
        line-height: 20px;
        
        color:$neutral-600;
        font-weight: 300;
        font-size: 13px;
    }
}