footer {
    padding: 0 20px;
    background-color: var(--app-menu-background);
    line-height: $footer_height;

    display: flex;
    gap:30px;

    .copyright,
    .privacy,
    .cookie {
        p, a {
            line-height: $footer_height;
            font-size: 10px;
            font-weight: 100;
            color: var(--app-menu-background-flat-text);
        }
    
        a {
            color: var(--app-menu-background-flat-text);
            display: block;
            text-decoration: none;
            &:hover {
                color: var(--app-menu-background-flat-text-hover);
                text-decoration: underline;
            }
        }
    }
}