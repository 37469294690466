main {
    background-color: var(--app-maincanvas-background);
    padding:40px 20px 40px $body_padding_right;
    overflow-y: scroll;
    border-radius: 0 0 0 10px;
    border-bottom: 1px solid var(--app-maincanvas-border-color);
    border-left: 1px solid var(--app-maincanvas-border-color);
    .module-body {
        .module-body-section-title {
            color:$primary-500;
            margin-bottom: 10px;
        }
        .module-body-section-subtitle {
            color:$primary-300;
            margin-bottom: 40px;
        }
    }
}