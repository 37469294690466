.importer {
    .debug {
        display: none;
    }

    .sectionTitle {
        width: 100%;
        display: block;
        margin-bottom: 10px;
    }

    .importer-form {
        .import-stage {
            &.import-stage-hidden {
                display: none;
            }


            .import-actions {
                display: grid;
                grid-template-columns: auto auto auto 100px;
                gap: 10px;
                margin-bottom: 40px;

                .form-element {
                    align-self: end;
                    label {
                        text-transform: none;
                    }

                    button {
                        width: 100%;
                        height: 44px;
                    }
                }
            }

            .form-element-control {
                .form-element-field {
                    min-height: 44px;

                    [type="file"] {
                        height: 44px;
                    }

                }
            }

        }
    }

    .importer-data-wrapper {
        overflow-x: scroll;

        table {
            width: auto;

            th,
            td {
                min-width: 100px;
            }

            .sourceHead {
                th {
                    background-color: var(--app-main);
                    color: var(--app-main-lightest);
                    font-size: 14px;
                    font-weight: 400;
    
                    &:first-child {
                        border-top-left-radius: 10px;
                    }
    
                    &:last-child {
                        border-top-right-radius: 10px;
                    }
                }
            }

            .mappingHead {
                background-color: var(--app-main-light);

                th {
                    background-color: transparent;

                    span {
                        font-size: 9px;
                        font-weight: 300;
                    }

                    .form-element-field-autocomplete {
                        max-width: 180px;
                        font-size: 14px;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}