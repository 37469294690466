.app-menu {
    padding: 10px 13px;

    .app-menu-portion {
        gap: 10px;

        >li {
            padding: 3px 3px 3px 10px;
            border-radius: 4px;

            >a {
                padding: 0;
                font-size: 16px;
                color: var(--app-menu-background-item-text);
                flex: initial;
                width: 100%;
                margin: 0;

                >span {
                    min-inline-size: 0;
                    margin-inline-end: 0;
                    width: 30px;

                    >svg {
                        color: var(--app-menu-background-item-icon);
                        margin: 0 auto;
                        height: 1.3em;
                    }

                    &.name {
                        margin-left: 10px;
                        font-size: 14px;
                        font-weight: var(--app-menu-background-item-textweight);
                        width: auto;
                    }
                }
            }

            &:hover:not(.app-menu-item-current) {
                background-color: var(--app-menu-background-item-hover);

                a {
                    color: var(--app-menu-background-item-hover-text);

                    svg {
                        color: var(--app-menu-background-item-hover-icon);
                    }

                    >span.name {
                        font-weight: var(--app-menu-background-item-hover-textweight);
                    }
                }
            }

            &.app-menu-item-current {
                background-color: var(--app-menu-background-item-highlight);

                >a {
                    color: var(--app-menu-background-item-highlight-text);

                    svg {
                        color: var(--app-menu-background-item-highlight-icon);
                    }

                    >span.name {
                        font-weight: var(--app-menu-background-item-highlight-textweight);
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 600px) {
    .app-menu {
        padding:10px;
        .app-menu-portion {
            flex-direction: row;
            padding: 0;
            > li {
                padding: 5px;
                flex: 1;
                > a {
                    flex-direction: column;
                    > span {
                        &.name {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}