.modulename {
    display: flex;
    .logo {
        display: none;
    }
    h2 {
        font-family: $font-primary;
        margin: 0;
        font-size: 30px;
        letter-spacing: 0px;
        font-weight: 100;
        line-height: $header_height - $header_padding-top;
        color: var(--app-module-title-color);
        .scope {
            font-size: 30px;
            color: var(--app-module-title-color);
            font-weight: 700;
        }
    }
}


@media screen and (max-width: 600px) {
    .modulename {
        gap:10px;
        .logo {
            display: inline;
            
            svg {
                width: 40px;
            }
        }
        h2 {
            color:var(--app-menu-app-title);
            font-size: 24px;
            .scope {
                color:var(--app-menu-app-title);
                font-size: 24px;
            }
        }
    }
}