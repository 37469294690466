.calculator-wrapper {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    gap: 20px;

    .calculator-form {
        grid-column: 1;
        grid-row: 1;


        form {
            display: flex;
            gap: 40px;

            fieldset {
                border: 2px solid $neutral-100;
                border-radius: 10px;
                margin-top: 30px;
                flex: 1 1 calc(50% - 20px);

                legend {
                    border: 2px solid $neutral-100;
                    background-color: $white;
                    border-radius: 14px;
                    line-height: 28px;
                    font-size: 14px;
                    padding: 0 10px;
                }

                &.servizi {
                    background-color: $neutral-100;
                }

                label {
                    font-size: 14px;
                    font-weight: 400;
                    display: block;
                }

                select,
                input {
                    width: 100%;
                    font-size: 18px;
                    font-weight: 300;
                    background-color: $white;
                    border: 0.5px solid $neutral-300;
                    border-radius: 5px;
                    height: 45px;
                    line-height: 45px;
                    padding: 0 10px;
                    margin-top: 5px;
                    margin-bottom: 15px;
                }
            }
        }

    }

    .calculator-action {
        grid-column: 1;
        grid-row: 2;
    }

    .calculator-result {
        grid-column: 1;
        grid-row: 3;

        #result {
            font-size: 90px;
            color: $primary-400;
            margin: 0;
            font-weight: 900;

        }
    }
}