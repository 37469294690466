.module-menu {
    display: flex;
    flex-direction: row;
    background-color: var(--app-maincanvas-background);
    padding: 0 40px;
    position: relative;
    &:after {
        content:"";
        position: absolute;
        bottom:0;
        left:40px;
        width: calc(100% - 80px);
        height: 1px;
        background-color: var(--app-surface-dimmed);
        z-index: 0;
    }
    ul.module-menu-portion {
        display: flex;
        flex-direction: row;
        gap:12px;
        li.module-menu-item {
            gap:5px;
            a {
                .icon {
                    color: var(--app-icon-primary-dimmed);
                    margin-right: 5px;
                }
                &.label {
                    font-size: 14px;
                    color: var(--app-menumodule-tab-color)
                }
                &:hover {
                    color: var(--app-menumodule-tab-color-hover)
                }
            }
            &.module-menu-item-selected {
                position: relative;
                z-index: 1;
                a {
                    &.label {
                        color: var(--app-menumodule-tab-color-selected)
                    }
                    .icon {
                        color: var(--app-icon-primary);
                    }
                }
                &:after {
                    content:"";
                    position: absolute;
                    height: 3px;
                    width: 100%;
                    background-color: var(--app-menumodule-tab-color-underline);
                    left: 0;
                    bottom: -7px;
                }
            }
        }
    }
}