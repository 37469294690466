.vehicle-ordered-termometer {
    display: grid;
    grid-template-columns: calc(100% - 100px);
    grid-template-rows: 40px;
    gap:5px 10px;
    
    .arrival {
        grid-column: 1;
        grid-row: 1;
    }
}