div.form-wrapper {
    border:unset;
    background-color: transparent;
    .form-section-header-wrapper {
        .form-section-header-content {
            .form-section-header-action {
                position: absolute;
                right: 0;
            }
        }
    }
    .form-section-fields-wrapper {
        .form-section-fields-content {
            display: grid;
            gap:20px 10px;
            grid-template-columns: repeat(12, 1fr);

            .form-element {
                grid-column: span 3;

                

                &.fullwidth,
                &.relationship,
                &.col-12 {
                    grid-column: span 12;
                }

                &.col-11 {
                    grid-column: span 11;
                }

                &.col-10 {
                    grid-column: span 10;
                }

                &.col-9 {
                    grid-column: span 9;
                }

                &.col-8 {
                    grid-column: span 8;
                }

                &.col-7 {
                    grid-column: span 7;
                }

                &.col-6,
                &.halfwidth {
                    grid-column: span 6;
                }

                &.col-5 {
                    grid-column: span 5;
                }

                &.col-4,
                &.thirdwidth {
                    grid-column: span 4;
                }

                &.col-3,
                &.quarterwidth {
                    grid-column: span 3;
                }

                &.col-2,
                &.sixthwidth {
                    grid-column: span 2;
                }

                &.col-1,
                &.singlewidth {
                    grid-column: span 1;
                }
            }

            .form-contract-readjustment {
                grid-column: span 12;
                tr {
                    th {
                        color:$primary-300;
                        text-align: left;
                        font-size: 14px;
                        border-bottom: 1px solid $neutral-200;
                    }
                    td {
                        width: 33%;
                        border-bottom: 1px solid $neutral-200;
                        padding: 3px;
                        padding-top: 0px;
                        .form-element {
                            label {
                                display: none;
                            }
                        }
                        &.label {
                            font-size: 13px;
                            padding-left: 10px;
                        }
                    }

                    &.sectiontitle {
                        td {
                            padding-top: 30px;
                            border-bottom: none;
                            &.label {
                                padding-left: 0;
                                span {
                                    font-size: 12px;
                                    line-height: 28px;
                                    height: 28px;
                                    border-radius: 14px;
                                    background-color: $primary-300;
                                    font-weight: 300;
                                    color: $white;
                                    padding: 0 10px ;
                                    display: inline-block;
                                }
                            }
                            &.new {
                                > * {display: none};
                            }
                        }
                    }
                }
            }
        }
        .MuiCardActionArea-focusHighlight {
            background-color: transparent;
            &:hover {
                background-color: pink;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    div.form-wrapper {
        padding:20px 0;
        .form-section-fields-wrapper {
            .form-section-fields-content {
                display: grid;
                gap:15px 10px;
                .form-element {
                    grid-column: span 6;
    
                    &.fullwidth,
                    &.relationship,
                    &.col-12 {
                        grid-column: span 12;
                    }
    
                    &.col-11 {
                        grid-column: span 12;
                    }
    
                    &.col-10 {
                        grid-column: span 12;
                    }
    
                    &.col-9 {
                        grid-column: span 12;
                    }
    
                    &.col-8 {
                        grid-column: span 12;
                    }
    
                    &.col-7 {
                        grid-column: span 12;
                    }
    
                    &.col-6,
                    &.halfwidth {
                        grid-column: span 12;
                    }
    
                    &.col-5 {
                        grid-column: span 6;
                    }
    
                    &.col-4,
                    &.thirdwidth {
                        grid-column: span 6;
                    }
    
                    &.col-3,
                    &.quarterwidth {
                        grid-column: span 6;
                    }
    
                    &.col-2,
                    &.sixthwidth {
                        grid-column: span 6;
                    }
    
                    &.col-1,
                    &.singlewidth {
                        grid-column: span 6;
                    }
                }
            }
        }    
    }
}