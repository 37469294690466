.legalentity-menu {
    margin: 10px 13px;
    background-color: var(--app-menu-background-item-highlight);
    padding:10px;
    border-radius: $borderradius;
    display: grid;
    grid-template-columns: 40px calc(100% - 66px) 26px;
    .legalentity-icon {
        position: relative;
        background-color: var(--app-menu-background-item-highlight);
        background-repeat: no-repeat;
        background-size: contain;
        background-blend-mode: var(--app-menu-background-blend-mode);
                    
        .company-avatar {
            position: absolute;
            top:3px;
            left: 3px;
        }
    }
    .legalentity-name {
        color:var(--app-menu-background-item-highlight-text);
        padding:0 10px;
        .label {
            font-size: 12px;
            font-weight: 300;
            display: block;
            line-height: 16px;
        }
        .value {
            font-size: 14px;
            font-weight: 600;
            display: block;
            line-height: 20px;
        }
    }
    .legalentity-list {
        position: relative;
        .form-element-field-select {
            background-color: transparent;
            border-color: transparent;
            box-shadow: none;
            > span.MuiSelect-indicator {
                display: none;
            }
            > button {
                font-size: 0;
            }
            z-index: 1;
        }

        .selector-icon {
            color: $white;
            position: absolute;
            top:10px;
            right: 0px;
            z-index: 0;
        }
    }
}