.company-avatar-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;

    .company-avatar {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 0.5px solid $neutral-300;
        border-radius: 50%;
        text-align: center;
        font-size: 16px;
        color: $white;
        font-weight: 500;
        background-color: $neutral-050;
        box-shadow: $boxshadow;
        flex-shrink: 0;
        overflow: hidden;

        img {
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: cover;
        }

        &.company-avatar-squared {
            width: 34px;
            height: 24px;
            line-height: 22px;
            border-radius: 3px;
            font-size: 12px;
            color: var(--app-menu-background-item-text);
            background-color: var(--app-menu-background) !important;
            border-color: var(--app-menu-background);

            border:1.5px solid $primary-300;
            position: relative;
            &:before {
                content:"";
                position: absolute;
                background-color:$primary-300;
                width: 15px;
                height: 1.5px;
                bottom:-8px;
                left:50%;
                transform: translateX(-50%);
            }
            &:after {
                content:"";
                position: absolute;
                background-color:$primary-300;
                width: 1.5px;
                height: 7px;
                bottom:-7px;
                left:50%;
                transform: translateX(-50%);
            }
        }
    }

    .company-name {
        line-height: 40px;
        flex-grow: 1;

        &.hasdetail {
            display: flex;
            flex-direction: column;
            line-height: 15px;
            .detail {
                font-size: 10px;
                color: $neutral-400;
            }
        }
    }

    &.company-avatar-error {
        .company-avatar {
            background-color: $danger-500;
            color: $danger-050;
        }

        .error {
            font-size: 12px;
            color: $danger-400;
        }
    }
}