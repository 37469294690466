.import-debugger-wrapper {
    background-color: $danger-050;
    border-radius: 10px;
    margin: -10px 20px 20px;
    padding: 20px;

    .title {
        color: $danger-500;
        line-height: 30px;
    }
    .informations {
        display: none;
        line-height: 20px;
        .filename,
        .rowid {
            font-size: 12px;
            border-radius: 13px;
            padding-left: 10px;
            padding-right: 10px;
        }

        .filename {
            background-color: $danger-100;
            color:$danger-400;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .rowid {
            padding-left: 5px;
            background-color:$danger-400;
            color: $danger-100;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .import-debugger {
        color: $neutral-700;
        font-size: 12px;
        margin-top: 5px;

        .import-debugger-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            button {
                color: $white;
                background-color: $danger-500;
                border:0;
                border-radius: 5px;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
            }
        }

        .json-wrapper {
            pre {
                border-radius: 10px;
                padding: 10px;
            }
        }
    }
}